<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      id="modal-confirmation-process"
      ref="modal-confirmation-process"
      centered
      size="sm"
      title="Êtes-vous sûr(e) de vouloir faire la liaison."
      hide-footer
    >
      <div v-if="isLinking" class="d-flex my-2 justify-content-center">
        <b-spinner variant="primary" small />
      </div>
      <div v-else class="d-flex justify-content-center align-items-center">
        <b-button
          size="sm"
          class="mr-1"
          variant="gradient-danger"
          @click="closeConfirmationModal()"
          >Annulé</b-button
        >
        <b-button size="sm" variant="gradient-primary" @click="createLinking()"
          >Oui, valider</b-button
        >
      </div>
    </b-modal>
    <b-modal
      centered
      hide-footer
      id="modal-demande-table"
      ref="modal-demande-table"
      size="xl"
      title="Vos demandes"
    >
      <div class="mb-1">
        <p class="text-secondary">
          Choisissez la demande à laquelle lié l'auto-évaluation.
        </p>
        <b-table
          ref="demandeTableDataRef"
          :items="demandeTableData"
          responsive
          :fields="demandeTableColumns"
          primary-key="id"
          show-empty
          empty-text="Aucune demande"
          :busy="isLoadingDemandeTableData"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>
          <template #cell(actions)="data">
            <div class="d-flex" style="gap: 7px">
              <b-button
                @click="openConfirmationModal(data.item.id)"
                variant="primary"
                size="sm"
              >
                <span class="text-nowrap"
                  >Lier

                  <i
                    class="las la-link la-lg d-inline-block"
                    style="margin-left: 5px"
                  ></i>
                </span>
              </b-button>
            </div>
          </template>
        </b-table>
        <!-- <div>
          <PaginationComponent
            :table_data="tableData"
            :per_page="paginationData.per_page"
            :totalRows="paginationData.total"
            @change="onPaginationChange"
          />
        </div> -->
      </div>
    </b-modal>

    <b-modal
      centered
      hide-footer
      no-close-on-backdrop
      id="modal-multi-1"
      size="lg"
      title="Auto-évaluations possibles"
      no-stacking
    >
      <div class="mb-1">
        <p class="text-secondary">
          Cliquez sur l'une des auto-évaluations pour la passer.
        </p>
        <div
          class="d-flex justify-content-center"
          v-if="isFetchingTypeAutoEval || isCreatingNewEvaluation"
        >
          <b-spinner variant="primary" size="sm" />
        </div>
        <div v-if="typeAutoEvaluationData.length > 0"></div>
        <b-list-group
          v-if="typeAutoEvaluationData.length > 0 && !isCreatingNewEvaluation"
          class="custom-list-group-2 w-100 shadow-sm"
        >
          <b-list-group-item
            v-for="(item, index) in typeAutoEvaluationData"
            :key="index"
            class="list-item"
            @click="selectedEvaluation(item)"
          >
            <span>
              <i
                class="las la-plus la-lg d-inline-block"
                style="margin-right: 5px"
              ></i>
              {{ item.title }}
            </span>
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-modal>

    <b-modal
      id="modal-questions-list"
      ref="modal-questions-list"
      centered
      hide-footer
      no-close-on-backdrop
      title="Grille des questions/réponses"
      size="xl"
    >
      <div
        v-if="dataQA && dataQA.chapters.length > 0"
        class="d-flex flex-column"
        style="gap: 1em"
      >
        <b-card
          v-for="(chapter, chapterIndex) in dataQA.chapters"
          :key="chapterIndex"
        >
          <template #header>
            <h6 class="mb-0">
              {{
                'Chapitre ' + Number(chapterIndex + 1) + ' : ' + chapter.label
              }}
            </h6>
          </template>
          <div class="d-flex flex-column" style="gap: 1em">
            <b-table
              v-for="(section, sectionIndex) in chapter.sections"
              :key="sectionIndex"
              bordered
              striped
              small
              head-variant="dark"
              :items="section.questions"
              :fields="question_answer_columns"
              caption-top
            >
              <template #table-caption
                ><b class="text-dark">{{ section.label }}</b></template
              >
            </b-table>
          </div>
        </b-card>
      </div>
    </b-modal>

    <!-- <b-modal centered
      hide-footer
      no-close-on-backdrop id="modal-multi-2" title="Second Modal">
    <p class="my-2">Second Modal</p>
  </b-modal> -->

    <b-modal
      id="modal-conf-evaluation"
      ref="modal-conf-evaluation"
      centered
      hide-footer
      no-close-on-backdrop
      title="Configuration de l'évaluation"
    >
      <b-form-group
        v-if="options_type_secteur_hebergement"
        label="Type d'hébergement"
        label-for="typeEtab"
      >
        <v-select
          id="typeEtab"
          v-model="selectedEtablissement"
          label="title"
          :options="options_type_secteur_hebergement"
          :reduce="(option) => option.id"
          :disabled="isCreatingNewEvaluation"
          placeholder="Choisissez le type d'hébergement"
        />
        <small v-if="typeEtablissementNeeded" class="text-danger"
          >Ce champ est requis.</small
        >
      </b-form-group>
      <!-- <div class="mb-1">
        <b-form-checkbox
          v-model="isSpecificClassement"
          value="yes"
          unchecked-value="no"
        >
          Choisir un classement spécifique
        </b-form-checkbox>
      </div> -->
      <!-- v-if="listForModal && isSpecificClassement === 'yes'" -->
      <b-form-group
        v-if="listForModal"
        label="Classement souhaité"
        label-for="classment"
      >
        <v-select
          id="classment"
          v-model="selectedClassementType"
          label="label"
          :options="listForModal"
          :reduce="(option) => option.value"
          :disabled="isCreatingNewEvaluation"
          placeholder="Choisissez un classement"
        />
        <small v-if="classementTypeNeeded" class="text-danger"
          >Ce champ est requis.</small
        >
      </b-form-group>
      <b-button
        block
        variant="primary"
        class="mb-1"
        :disabled="isCreatingNewEvaluation || !selectedEtablissement"
        @click="new_evaluation()"
        >Créer l'évaluation</b-button
      >
    </b-modal>
    <b-modal
      id="modal-result-evaluation"
      ref="modal-result-evaluation"
      centered
      size="lg"
      title="Résultat de l'auto-évaluation"
      hide-footer
    >
      <div v-if="isLoadingResult == true" class="d-flex justify-content-center">
        <b-spinner variant="primary" />
      </div>
      <div v-else>
        <div v-if="evaluationResult" class="px-4 py-3">
          <span
            ><b>Evaluation</b>:
            <span class="text-primary d-inline-block ml-1">{{
              evaluationResult.title
            }}</span></span
          >
          <hr />
          <b class="mb-1 d-inline-block"
            >Rapport d'éligibilité par classement</b
          >
          <br />
          <div>
            <div class="d-flex align-items-center" style="gap: 1em">
              <b-button
                v-b-toggle="'collapse-1'"
                size="sm"
                style="width: 100px; margin: 4px 0"
                :variant="
                  evaluationResult.results.is_eligible
                    ? 'gradient-success'
                    : 'gradient-danger'
                "
                >{{
                  evaluationResult.results.class &&
                  evaluationResult.results.class
                }}
                étoile</b-button
              >
              <b
                v-if="evaluationResult.results.is_eligible"
                class="text-success"
                >ELIGIBLE</b
              >
              <b
                v-if="!evaluationResult.results.is_eligible"
                class="text-danger"
                >NON ELIGIBLE</b
              >
            </div>
            <b-collapse id="collapse-1" visible class="mt-1">
              <ResultsOutput
                :results="evaluationResult.results.data"
                :totals="evaluationResult.results.totals"
              />
            </b-collapse>
          </div>

          <hr />
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-is-downloading"
      ref="modal-is-downloading"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      class="endmodal"
      centered
      size="sm"
      hide-footer
    >
      <div class="d-flex justify-content-center align-items-center">
        <!-- <b-spinner variant="primary" small class="mb-1" /> -->
        <small class="d-block text-primary">Téléchargement en cours...</small>
      </div>
    </b-modal>
    <div class="bg-white shadow-sm px-2 py-1 mb-1">
      <!-- <div>
        <h1></h1>
      </div> -->
      <div class="d-flex justify-content-between flex-wrap">
        <h2 class="my-auto">
          <!-- {{ $t('evaluations.evaluation_list.title') }} <br /> -->
          Auto-évaluations
        </h2>
        <b-button v-b-modal.modal-multi-1 variant="gradient-primary" size="sm">
          <span class="text-nowrap">
            <i
              class="las la-plus la-lg d-inline-block"
              style="margin-right: 5px"
            ></i>
            Créer une auto-évaluation
          </span>
        </b-button>
      </div>
    </div>

    <!-- <div>
      <ResultsOutput2
        ref="resultOutputExport"
        v-if="evaluationResult"
        :results="evaluationResult.results"
      />
    </div> -->
    <!-- <div>
      <b-button @click="download_test_qa()">DONWLA</b-button>
      <ResultsOutput3
        v-if="testQA"
        ref="testQAexport"
        :chapters="testQA.chapters"
      />
    </div> -->

    <vue-html2pdf
      v-show="false"
      ref="printableResults"
      class="border rounded"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="true"
      :manual-pagination="false"
      :paginate-elements-by-height="1000"
      filename="BILAN_EVALUATION"
      :pdf-quality="2"
      :pdf-margin="20"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
    >
      <section slot="pdf-content">
        <div v-if="evaluationResult" class="px-4 py-3">
          <span
            ><b>Evaluation</b>:
            <span class="text-primary d-inline-block ml-1">{{
              evaluationResult.title
            }}</span></span
          >
          <hr />
          <b class="mb-1 d-inline-block"
            >Rapport d'éligibilité par classement</b
          >
          <br />
          <div>
            <div class="d-flex align-items-center" style="gap: 1em">
              <b-button
                v-b-toggle="'collapse-1'"
                size="sm"
                style="width: 100px; margin: 4px 0"
                :variant="
                  evaluationResult.results.is_eligible
                    ? 'gradient-success'
                    : 'gradient-danger'
                "
                >{{
                  evaluationResult.results.class &&
                  evaluationResult.results.class
                }}
                étoile</b-button
              >
              <b
                v-if="evaluationResult.results.is_eligible"
                class="text-success"
                >ELIGIBLE</b
              >
              <b
                v-if="!evaluationResult.results.is_eligible"
                class="text-danger"
                >NON ELIGIBLE</b
              >
            </div>
            <b-collapse id="collapse-1" visible class="mt-1">
              <ResultsOutput
                :results="evaluationResult.results.data"
                :classement-number="
                  String(
                    evaluationResult.results.class &&
                      evaluationResult.results.class
                  )
                "
              />
            </b-collapse>
          </div>

          <hr />
        </div>
        <!-- <component :is="selectedForm.vueComponents.pv" :data="validOutput" /> -->
      </section>
    </vue-html2pdf>

    <!-- <vue-html2pdf
      v-show="false"
      ref="qaPdf"
      class="border rounded"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="true"
      :manual-pagination="false"
      :paginate-elements-by-height="1000"
      filename="Grille des questions/réponses"
      :pdf-quality="2"
      :pdf-margin="20"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
    >
      <section slot="pdf-content"> -->
    <!-- class="d-flex flex-column px-4 py-3" -->
    <!-- <div
        class="d-flex flex-column"
          style="gap: 1em"
          v-if="dataQA && dataQA.chapters.length > 0"
        >
          <b-card
            v-for="(chapter, chapterIndex) in dataQA.chapters"
            :key="chapterIndex"
          >
            <template #header>
              <h6 class="mb-0">
                {{ 'Chapitre ' + chapterIndex + ' : ' + chapter.label }}
              </h6>
            </template>
            <div class="d-flex flex-column" style="gap: 1em">
              <b-table
                v-for="(section, sectionIndex) in chapter.sections"
                :key="sectionIndex"
                bordered
                striped
                small
                head-variant="dark"
                :items="section.questions"
                :fields="question_answer_columns"
                caption-top
              >
                <template #table-caption
                  ><b class="text-dark">{{ section.label }}</b></template
                >
              </b-table>
            </div>
          </b-card>
        </div> -->
    <!-- </section>
    </vue-html2pdf> -->

    <div class="">
      <b-card title="Historique des auto-évaluations">
        <b-table
          ref="refFormsTable"
          :items="tableData"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          striped
          empty-text="Aucune auto-évaluation"
          :busy="isLoading"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>
          <template #cell(title)="data">
            <div style="display: flex; flex-direction: column; gap: 5px">
              <b class="d-inline-block" style="max-width: 314px">
                {{ data.item.title }}
              </b>
              <span class="d-inline-block"
                ><em style="text-decoration: underline">Établissement:</em>
                <b> {{ data.item.type_etablissement.title }}</b></span
              >
              <span class="d-inline-block"
                ><em style="text-decoration: underline">Classement:</em>
                <span v-if="data.item.class == null" class="ml-1"
                  >Non renseigné</span
                >
                <b v-else>
                  <span class="d-inline-block ml-1">{{ data.item.class }}</span>
                  <span style="transform: translateY(-7px)"> ⭐</span></b
                >
                {{ data.item.class }}
              </span>
              <!-- <i
                  class="las la-star-solid la-lg d-inline-block"
                  style="margin-left: 5px; color: orange !important"
                ></i> -->
            </div>
          </template>
          <template #cell(status)="data">
            <h5>
              <b-badge
                pill
                :variant="
                  data.item.status === 'Terminated' ? 'success' : 'secondary'
                "
                >{{
                  data.item.status === 'Terminated' ? 'Terminé' : 'En cours'
                }}</b-badge
              >
            </h5>
          </template>
          <template #cell(created_at)="data">
            {{ data.item.created_at }}
          </template>
          <template #cell(updated_at)="data">
            {{ data.item.updated_at }}
          </template>
          <!-- @click="continueEvaluation()" -->
          <template #cell(action)="data">
            <div class="d-flex" style="gap: 7px; flex-wrap: wrap">
              <b-button
                :disabled="
                  isCreatingNewEvaluation || data.item.status === 'Terminated'
                "
                @click="continueEvaluation(data.item.id)"
                variant="primary"
                size="sm"
              >
                <span class="text-nowrap"
                  >Continuer

                  <i
                    class="las la-arrow-right la-lg d-inline-block"
                    style="margin-left: 5px"
                  ></i>
                </span>
              </b-button>
              <!-- @click="openQuestionListModal(data.item)" -->
              <b-button
                @click="download_question_answer(data.item)"
                :disabled="isCreatingNewEvaluation"
                variant="gradient-dark"
                size="sm"
              >
                <span class="text-nowrap"
                  >Grille

                  <i
                    class="las la-download la-lg d-inline-block"
                    style="margin-left: 5px"
                  ></i>
                </span>
              </b-button>
              <!-- <b-button
                :disabled="isCreatingNewEvaluation"
                @click="download_QA(data.item)"
                variant="gradient-dark"
                size="sm"
              >
                <span class="text-nowrap"
                  ><i
                    class="las la-stop la-lg d-inline-block"
                    style="margin-left: 5px"
                  ></i>
                </span>
              </b-button> -->
              <b-button-group>
                <b-button
                  :disabled="
                    isCreatingNewEvaluation || data.item.status !== 'Terminated'
                  "
                  @click="seeResult(data.item)"
                  variant="primary"
                  size="sm"
                >
                  <span class="text-nowrap"
                    >Résultats

                    <i
                      class="las la-medal la-lg d-inline-block"
                      style="margin-left: 5px"
                    ></i>
                  </span>
                </b-button>
                <!-- @click="downloadPDF(data.item)" -->
                <b-button
                  :disabled="
                    isCreatingNewEvaluation || data.item.status !== 'Terminated'
                  "
                  @click="download_result(data.item)"
                  variant="gradient-success"
                  size="sm"
                >
                  <span class="text-nowrap">
                    <i class="las la-download la-lg d-inline-block"></i>
                  </span>
                </b-button>
              </b-button-group>
              <!-- :disabled="
                  isCreatingNewEvaluation || data.item.status !== 'Terminated'
                " -->
              <b-button
                :disabled="
                  isCreatingNewEvaluation ||
                  data.item.demande_id !== null ||
                  isLinking ||
                  data.item.status !== 'Terminated'
                "
                @click="openLinkingModal(data.item.id)"
                variant="dark"
                size="sm"
              >
                <span class="text-nowrap">
                  Lié a une demande
                  <i
                    class="las la-link la-lg d-inline-block"
                    style="margin-left: 5px"
                  ></i>
                </span>
              </b-button>
            </div>
          </template>
        </b-table>
        <div>
          <PaginationComponent
            :table_data="tableData"
            :per_page="paginationData.per_page"
            :totalRows="paginationData.total"
            @change="onPaginationChange"
          />
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardText,
  BRow,
  BCol,
  BCollapse,
  BForm,
  BFormInput,
  BFormGroup,
  BFormRating,
  BFormCheckbox,
  BButton,
  BButtonGroup,
  BTable,
  BLink,
  BModal,
  VBModal,
  BBadge,
  BSpinner,
  VBToggle,
  BDropdown,
  BDropdownItem,
  BListGroup,
  BListGroupItem,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { onClickOutside } from '@vueuse/core'
import * as XLSX from 'xlsx/xlsx.mjs'
import VueHtml2pdf from 'vue-html2pdf'
import flatPickr from 'vue-flatpickr-component'
import { mapActions } from 'vuex'
import localstorageService from '@/services/localstorage/localstorage.service'
import utilsService from '@/services/utils/utils.service'
// store modules and vuex utilities
import etablissementsStoreModule from '@/store/etablissements'
import evaluationsStoreModule from '@/store/evaluations'
import formulaireDemandesStoreModule from '@/store/formulaire'

// eslint-disable-next-line import/no-cycle
import {
  registerStoreModule,
  // unregisterStoreModule,
} from '@/helpers/vuex-utils'

import VueApexCharts from 'vue-apexcharts'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ResultsOutput from '@/components/ResultOutput.vue'
import ResultsOutput2 from '@/components/ResultOutput2.vue'
import ResultsOutput3 from '@/components/ResultOutput3.vue'
import DateRangeWidget from '@/components/DateRangeWidget.vue'
import PaginationComponent from '@/components/PaginationComponent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    // ProductsListFilters,
    // UserListAddNew,
    ResultsOutput,
    ResultsOutput2,
    ResultsOutput3,
    BCard,
    BCardBody,
    BRow,
    BCardText,
    BCollapse,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRating,
    BFormCheckbox,
    BButton,
    BButtonGroup,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BLink,
    BBadge,
    BSpinner,
    BModal,
    vSelect,

    PaginationComponent,
    AppCollapse,
    AppCollapseItem,
    flatPickr,
    DateRangeWidget,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    VueApexCharts,

    VueHtml2pdf,
  },

  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  mounted() {
    // this.getListTypesEtablissement();
    const listOfPossibleAutoEval = this.$refs.listOfPossibleAutoEval
    onClickOutside(listOfPossibleAutoEval, () => {
      this.showListOfPossibleAutoEval = false
    })
  },
  data() {
    return {
      // MODAL-DATA
      evaluationResult: null,
      options_type_secteur_hebergement: [],
      // -------------------------------
      isLoading: false,
      // tableData: [],
      // tableColumns: [
      //   { key: 'fullname', label: 'table_columns.fullname', sortable: true },
      //   { key: 'phone_number', label: 'table_columns.phone', sortable: true },
      //   {
      //     key: 'object',
      //     label: 'table_columns.object',
      //     sortable: true,
      //     thStyle: { width: '20%' },
      //   },
      //   { key: 'profile', label: 'table_columns.company', sortable: true },
      //   { key: 'status', label: 'table_columns.status', sortable: true },
      //   { key: 'created_at', label: 'table_columns.date', sortable: true },
      //   { key: 'actions', label: 'table_columns.actions' },
      // ],
      tableColumns: [
        {
          key: 'title',
          label: 'Libellé',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Statut',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Date de création',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Dernière modification',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Actions',
        },
      ],
      fieldsSections: [
        {
          key: 'label',
          label: 'Label',
        },
        {
          key: 'percentage',
          label: '%',
        },
      ],
      tableData: [],
      question_answer_columns: [
        {
          key: 'question',
          label: 'Question',
        },
        {
          key: 'selected_id',
          label: 'Réponse',
        },
      ],
      demandeTableColumns: [
        {
          key: 'id',
          label: '#',
        },
        {
          key: 'data.reference_paiement',
          label: 'Référence',
        },
        {
          key: 'type_demande.title',
          label: 'Titre',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
      demandeTableData: [],
      linking_payload: {
        demande_id: null,
        auto_evaluation_id: null,
      },
      isLoadingDemandeTableData: false,
      isLinking: false,
      createdAutoEvalDatas: null,
      typeAutoEvaluationData: [],
      evaluationSelected: null,
      isFetchingTypeAutoEval: false,
      isCreatingNewEvaluation: false,
      filteredTableData: [],
      paginationData: {
        currentPage: 1,
        total: 0,
        per_page: 20,
        metaData: { from: 0, to: 0 },
      },
      filteredOptions: [],
      listProfils: [],
      listStatus: [],
      listMetiers: [],

      filters: {
        name: null,
        sector: null,
        object: null,
        message: null,
        profile_id: null,
        status: null,
        periode: null,
      },
      isSpecificClassement: 'no',
      selectedClassementType: null,
      selectedEtablissement: null,
      classementTypeNeeded: false,
      typeEtablissementNeeded: false,
      listForModal: [],
      showListOfPossibleAutoEval: false,
      isDownloading: false,
      htmlPdfOps: {
        margin: '4em',

        filename: `hehezzhe.pdf`,

        image: {
          type: 'jpeg',
          quality: 0.98,
        },

        enableLinks: false,

        html2canvas: {
          scale: 1,
          useCORS: true,
        },

        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'portrait',
        },
      },
      dataQA: null,
      testQA: null,
      isLoadingResult: false,
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        // this.loadProfils();
        // this.loadMetiers();
        this.loadAutoEvaluationType()
        this.getListTypeHebergement()
        this.searchEvaluation()
      },
    },
    per_page: {
      immediate: true,
      handler(val, old) {
        if (this.tableData && this.tableData[val - 1]) {
          this.filteredTableData = this.customCloneDeep(
            this.tableData.slice(0, val - 1)
          )
        }
      },
    },
    tableData: {
      immediate: true,
      handler(val, old) {
        if (this.tableData) {
          this.filteredTableData = this.customCloneDeep(this.tableData)
        }
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        this.searchEvaluation()
      },
    },
    'paginationData.per_page': {
      handler(val, old) {
        this.searchEvaluation()
      },
    },
  },

  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStoreModule },
      { path: 'evaluations', module: evaluationsStoreModule },
      { path: 'formulaireDemandes', module: formulaireDemandesStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    return {
      requiredStoreModules,
    }
  },

  // beforeDestroy() {
  //   unregisterStoreModule(this.requiredStoreModules)
  // },

  methods: {
    ...mapActions('etablissements', {
      action_searchEtablissement: 'searchEtablissement',
      action_getListTypesEtablissement: 'getListTypesEtablissement',
      action_searchTypeEtablissement: 'searchTypeEtablissement',
    }),
    ...mapActions('formulaireDemandes', {
      action_searchFormulaires: 'searchFormulaires',
    }),
    ...mapActions('evaluations', {
      action_downloadQa: 'downloadQaEvaluation',
      action_downloadResult: 'downloadEvaluationResult',
      action_createEvaluation: 'createEvaluation',
      action_searchEvaluation: 'searchEvaluation',
      action_searchTypeEvaluation: 'searchTypeEvaluation',
      action_updateEvaluation: 'updateEvaluation',
      action_getEvaluation: 'getEvaluation',
    }),
    // --------------------------------------------------------------
    // Chargement des types detablissements
    getListTypeHebergement() {
      this.action_searchTypeEtablissement()
        .then((res) => {
          this.options_type_secteur_hebergement = res.data.data || []
        })
        .catch((err) => {})
    },
    // Chargement des profils
    async loadProfils() {
      try {
        const response = await this.action_searchEtablissement()
        if (response.status === 200) {
          this.listProfils = response.data.data
        }
      } catch (error) {
        console.log('Erreur lors du chargement de la liste des profils', error)
      }
    },
    // Chargement des métiers
    async loadMetiers() {
      try {
        const response = await this.action_getListTypesEtablissement()
        if (response.status === 200) {
          this.listMetiers = response.data.data
        } else {
          console.log("La réponse n'a pas un status HTTP 200")
        }
      } catch (error) {
        console.log('Erreur lors du chargement de la liste des métiers', error)
      }
    },
    // Chargement type d'auto-evalautions
    loadAutoEvaluationType() {
      this.isFetchingTypeAutoEval = true
      console.log('🟢🔴🟢🔴🟢🔴 ')
      const params = {
        type: 'evaluation',
        secteur_id: utilsService.userData.profile.secteur_id,
        per_page: 100,
      }
      this.action_searchTypeEvaluation(params)
        // this.action_searchTypeEvaluation()
        .then(async (response) => {
          console.log('🟢🔴 response::: ', response)
          if (response.status === 200) {
            this.typeAutoEvaluationData = response.data.data || []
            this.isFetchingTypeAutoEval = false
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                err.message ||
                "Une erreur est survenue lors du chargement des types d'auto-évaluations!",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.isFetchingTypeAutoEval = false
        })
    },
    // Recherche de auto évaluation
    searchEvaluation() {
      try {
        this.isLoading = true
        const params = {
          ...this.filters,
          page: this.paginationData.currentPage - 1,
          per_page: this.paginationData.per_page,
        }
        console.log('params::: ⭕️', params)
        // return;

        this.action_searchEvaluation(params)
          .then(async (response) => {
            if (response.status === 200) {
              console.log('response::: ⭕️⭕️⭕️', response)
              this.tableData = response.data.data
              this.isLoading = false
              // --------------------------------------------------------------
              this.paginationData.total = response.data.meta.total || 0
              if (this.paginationData.metaData.from === 0) {
                this.paginationData.metaData.from += 1
              } else {
                this.paginationData.metaData.from =
                  this.paginationData.per_page *
                    this.paginationData.currentPage -
                  this.paginationData.per_page
              }
              this.paginationData.metaData.to =
                this.paginationData.per_page * this.paginationData.currentPage -
                this.paginationData.per_page +
                response.data.meta.total
              // --------------------------------------------------------------
            }
          })
          .catch((err) => {
            this.isLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  err.message ||
                  'Une erreur est survenue lors du chargement des auto-évaluations!',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } catch (err) {
        this.isLoading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              err.message ||
              'Une erreur est survenue lors du chargement des auto-évaluations!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    // Nouvelle auto évaluation
    new_evaluation() {
      // FOR ETAB 🔽
      this.isCreatingNewEvaluation = true
      if (!this.selectedEtablissement) {
        this.typeEtablissementNeeded = true
        this.isCreatingNewEvaluation = false
        return
      }
      this.typeEtablissementNeeded = false
      // FOR CLASSEMENT 🔽
      this.isCreatingNewEvaluation = true
      if (!this.selectedClassementType) {
        this.classementTypeNeeded = true
        this.isCreatingNewEvaluation = false
        return
      }
      this.classementTypeNeeded = false

      this.$refs['modal-conf-evaluation'].hide()
      const evaluationData = this.evaluationSelected
      const params = {
        user_id: utilsService.userData.id,
        // data: evaluationData.data,
        title: evaluationData.title,
        // secteur_id: utilsService.userData.profile.secteur_id,
        type_auto_evaluation_id: evaluationData.id,

        type_etablissement_id: this.selectedEtablissement,
        class: String(this.selectedClassementType),
      }
      console.log('🔰🔰🔰:::🔰🔰🔰 ', params)
      // return;
      this.action_createEvaluation(params)
        .then(async (response) => {
          // if (response.status === 200)
          this.createdAutoEvalDatas = response.data.data
          console.log('✅ EVALUATION CREATED', this.createdAutoEvalDatas)
          this.continueEvaluation(response.data.id)
          // this.searchEvaluation();

          // Redirect on auto-evaluation page.
          // this.$router.push({
          //   name: 'evaluation-procedure',
          //   params: { id: evaluation.id },
          // });
          this.isCreatingNewEvaluation = false
        })
        .catch((err) => {
          console.log(
            "Erreur survenue lors de la création de l'auto-évaluation.",
            err
          )
          this.isCreatingNewEvaluation = false
        })
    },
    // Type dauto evaluation selectionner
    selectedEvaluation(evaluation) {
      this.evaluationSelected = evaluation
      this.listForModal = evaluation.ranks
      this.$refs['modal-conf-evaluation'].show()
    },
    // Continuer une auto-évaluation
    continueEvaluation(evaluationId) {
      this.$router.push({
        name: 'evaluation-procedure',
        params: { id: evaluationId },
      })
    },
    async seeResult(data) {
      this.evaluationResult = null
      let result = null

      this.$refs['modal-result-evaluation'].show()
      this.isLoadingResult = true
      await this.action_getEvaluation(data.id)
        .then((response) => {
          if (response.status === 200) {
            result = response.data
            console.log('🚀 ~ file: Index.vue:seeResult ~ result', result)
          }
          this.isLoadingResult = false
        })
        .catch((err) => {
          this.isLoadingResult = false

          console.log('err', err)
        })
      const remappedChapter = this.remapChapter(result.data.chapters)
      // console.log('remapped', remappedChapter);
      let res = result
      res.data.chapters = remappedChapter
      this.evaluationResult = res
    },
    remapChapter(chapters) {
      const chaptersWithPercentage = chapters.map((chapter) => {
        const sectionsWithPercentage = chapter.sections.map((section) => {
          const totalQuestions = section.questions.length
          const selectedQuestions = section.questions.filter(
            (question) => question.selected_id !== null
          ).length
          const percentage = (selectedQuestions / totalQuestions) * 100
          return {
            ...section,
            percentage: percentage.toFixed(2), // Rounded to 2 decimal places
          }
        })
        return {
          ...chapter,
          sections: sectionsWithPercentage,
        }
      })
      return chaptersWithPercentage
    },
    // handleNewAutoEval() {
    //   if (this.showListOfPossibleAutoEval === true)
    //     this.showListOfPossibleAutoEval = false;
    //   if (this.showListOfPossibleAutoEval === false)
    //     this.showListOfPossibleAutoEval = true;
    // },
    // Changement de page
    onPaginationChange(val) {
      this.paginationData.currentPage = val.currentPage
      this.paginationData.per_page = val.per_page
    },
    async downloadPDF(data) {
      this.isDownloading = true
      this.$refs['modal-is-downloading'].show()
      this.evaluationResult = null
      const remappedChapter = this.remapChapter(data.data.chapters)
      // console.log('remapped', remappedChapter);
      // console.log('res', data);
      let res = data
      res.data.chapters = remappedChapter
      this.evaluationResult = res
      console.log('🚦🚦🚦', this.evaluationResult)
      await this.$refs.printableResults.generatePdf()
      this.$refs.printableResults.$once('hasDownloaded', () => {
        this.$refs['modal-is-downloading'].hide()
        this.isDownloading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Document téléchargé avec succès',
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        })
      })
    },

    openDlModal() {
      this.isDownloading = true
      // this.$refs['modal-is-downloading'].show();
    },
    closeDlModal() {
      this.isDownloading = false
      // this.$refs['modal-is-downloading'].hide();
    },
    async exportExcel(data) {
      this.openDlModal()
      this.evaluationResult = null
      const remappedChapter = this.remapChapter(data.data.chapters)
      let res = data
      res.data.chapters = remappedChapter
      this.evaluationResult = res
      await this.$nextTick()
      this.exporter(data.title, data.updated_at)
      this.closeDlModal()
    },
    exporter(name, lastDate) {
      try {
        const wb = XLSX.utils.table_to_book(
          this.$refs.resultOutputExport.$refs.tableToExportInExcel
        )
        /* Export to file (start a download) */
        XLSX.writeFile(
          wb,
          `Rapport d'éligibilité - ${name + ' - ' + lastDate}.xlsx`
        )
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Document téléchargé avec succès.',
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        })

        // this.isDownloading = false;
        // this.$refs['modal-is-downloading'].hide();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Erreur survenue lors du téléchargement du document.',
            icon: 'ThumbsDownIcon',
            variant: 'error',
          },
        })
      }
    },
    openLinkingModal(auto_evaluation_id) {
      this.linking_payload.auto_evaluation_id = auto_evaluation_id
      this.$refs['modal-demande-table'].show()
      this.isLoadingDemandeTableData = true

      const params = {
        identity: utilsService.userData.identity,
        hasnt_audit: true,
        hasnt_evaluation: true,
      }
      this.action_searchFormulaires(params)
        .then(async (response) => {
          if (response.status === 200) {
            this.demandeTableData = response.data.data || []
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                err.message ||
                'Une erreur est survenue lors du chargement des formulaires de demandes!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.isLoadingDemandeTableData = false
        })
    },
    createLinking() {
      // Create linking logic
      this.isLinking = true
      this.$refs['modal-demande-table'].show()
      const payload = {
        id: this.linking_payload.auto_evaluation_id,
        params: {
          demande_id: this.linking_payload.demande_id,
        },
      }
      this.action_updateEvaluation(payload)
        .then(async (response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Auto-évaluation lié avec succès !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$refs['modal-demande-table'].hide()
          this.$refs['modal-confirmation-process'].hide()
          this.searchEvaluation()
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                err.message ||
                'Une erreur est survenue lors du chargement des formulaires de demandes!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.isLinking = false
        })
    },
    openConfirmationModal(demande_id) {
      this.linking_payload.demande_id = demande_id
      this.$refs['modal-confirmation-process'].show()
    },
    closeConfirmationModal() {
      this.linking_payload = {
        demande_id: null,
        auto_evaluation_id: null,
      }
      this.$refs['modal-confirmation-process'].hide()
    },
    download_QA(data) {
      this.isDownloading = true
      this.$refs['modal-is-downloading'].show()
      this.$refs.qaPdf.generatePdf()

      this.$refs.qaPdf.$once('hasDownloaded', () => {
        this.$refs['modal-is-downloading'].hide()
        this.isDownloading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Document téléchargé avec succès',
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        })
      })
    },
    openQuestionListModal(data) {
      this.dataQA = data.data
      this.$refs['modal-questions-list'].show()
    },
    closeQuestionListModal() {
      this.$refs['modal-questions-list'].hide()
    },
    download_question_answer(data) {
      this.action_downloadQa({ id: data.id })
        // this.action_searchTypeEvaluation()
        .then(async (response) => {
          console.log('🟢🟢🟢🟢🟢', response, '🟢🟢🟢🟢🟢🟢')
          const res = await fetch(response.data.data)
          const blob = await res.blob()
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Auto-Evaluation-${data.id}.pdf`
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch((err) => {
          console.log('🔴🔴🔴', err)
        })
    },
    download_result(data) {
      this.action_downloadResult({ id: data.id })
        // this.action_searchTypeEvaluation()
        .then(async (response) => {
          console.log('🟢🟢🟢🟢🟢', response, '🟢🟢🟢🟢🟢🟢')
          const res = await fetch(response.data.data)
          const blob = await res.blob()
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Bilan-Auto-Evaluation-${data.id}.pdf`
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch((err) => {
          console.log('🔴🔴🔴', err)
        })
    },
    // testExcel(data) {
    //   this.testQA = data.data;
    // },
    // download_test_qa() {
    //   const wb = XLSX.utils.table_to_book(
    //     this.$refs.testQAexport.$refs.tableToExportInExcel
    //   );
    //   /* Export to file (start a download) */
    //   XLSX.writeFile(wb, `TEST_QA.xlsx`);
    // },
  },
}
</script>

<style>
::v-deep .dropdown-menu {
  padding: 0 !important;
}
::v-deep .dropdown-item {
  padding: 0;
  margin: 0;
}
.typeEvaluation {
  text-decoration: none;
  color: #0e6258;
}
.typeEvaluation:hover {
  color: #fff;
  background-color: #0e6258;
}

.custom-list-group-2 {
  max-height: 200px;
  overflow-y: auto;
  /* z-index: 100; */
}
.custom-list-group {
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 45px;
  /* z-index: 100; */
}
.custom-list-group:hover .list-item:hover {
  background-color: #0e6258;
  color: #fff;
}
.custom-list-group-2:hover .list-item:hover {
  background-color: #0e6258;
  color: #fff;
}
.list-item {
  cursor: pointer;
}
.new-autoevalbox {
  position: relative;
}
.auto-evaluation-sections {
  display: grid;
  align-items: flex-start;
  column-gap: 2em;
}
/* MEDIA QUERIES */
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .auto-evaluation-sections {
    grid-template-columns: 350px 1fr;
    /* grid-template-columns: 350px 1fr 350px; */
  }
}
@media (min-width: 1100px) {
}
@media (min-width: 1400px) {
}
::v-deep .nohead {
  display: none !important;
}
.papera4 {
  width: 595px;
  height: 842px;
  border: 1px solid red;
}
</style>
