<template>
  <table ref="tableToExportInExcel">
    <template v-for="(chapter, chapterIndex) in chapters">
      <tr>
        <td colSpan="8">
          <template v-for="(char, charaIndex) in chapter.label.length"
            >-</template
          >-------------------------
        </td>
      </tr>
      <tr>
        <th colSpan="8">
          {{ 'Chapitre ' + chapterIndex + ' : ' + chapter.label }}
        </th>
      </tr>
      <tr>
        <td colSpan="8">
          <template v-for="(char, charaIndex) in chapter.label.length"
            >-</template
          >-------------------------
        </td>
      </tr>
      <tbody v-for="(section, sectionIndex) in chapter.sections">
        <tr>
          <td colSpan="8">{{ section.label }}</td>
        </tr>
        <tr>
          <td colSpan="8">
            ----------------------------------------------------------------------------------------------------
          </td>
        </tr>
        <tr>
          <th>Question</th>
          <th>                                        </th>
          <th>Reponse</th>
        </tr>
        <tr
          v-for="(question, questionIndex) in section.questions"
          :key="questionIndex"
        >
          <td>◾ {{ question.question }}</td>
          <td>                                        </td>
          <td>* {{ question.selected_id }}</td>
        </tr>
        <tr>
          <td colSpan="8"></td>
        </tr>
      </tbody>
    </template>
  </table>
</template>

<script>
export default {
  mounted() {},
  props: {
    chapters: {
      type: Array,
      required: true,
    },
  },
  methods: {},
  computed: {},
};
</script>
